<template>
	<div class="inquiry-card">
		<h4>비밀번호 재설정이 완료되었습니다.</h4>
		<p>
			로그인 후 FCTS 서비스를 이용해 주시길 바랍니다. <br />
			감사합니다.
		</p>

		<div class="button-group-row">
			<router-link to="/login"
				><button class="btn--md btn--fill-gray">로그인</button></router-link
			>
			<router-link to="/"
				><button class="btn--md btn--fill-accent" href="/">
					홈으로
				</button></router-link
			>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
